import React from 'react';
import { Link } from 'gatsby';
import SVG from 'react-inlinesvg';

import LogoSrc from '../img/svg/logo.svg';
import '../styles/404.scss';

const NotFoundPage = () => (
  <main className="NotFound">
    <SVG src={LogoSrc} className="NotFound__logo" />

    <h1 className="NotFound__heading">NOT FOUND</h1>

    <p>You just hit a route that doesn&#39;t exist... the&nbsp;sadness.</p>

    <Link to="/" className="Button NotFound__button">
      Go back home
    </Link>
  </main>
);

export default NotFoundPage;
